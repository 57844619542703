import { useState } from "react";
import Header from "./components/Header";
import TioTransparent from "./assets/tio-triangle.png";
import FastLock from "./assets/fast-lock.jpg";
import Banner2 from "./assets/banner2.jpg";
import AussieMap from "./assets/about-aussie-map.png";

const AboutUs = () => {
  const [openSheet, setOpenSheet] = useState(false);

  const onClick = () => {
    console.log("clicked");
    setOpenSheet(true);
  };
  return (
    <div className="h-full w-screen overflow-y-auto overflow-x-hidden relative bg-white ">
      <Header onClick={onClick} bgColor="#000" />
      <div className="w-full relative">
        <img
          src={FastLock}
          className="w-full h-[700px]  object-center object-cover z-10"
          alt="fast-lock"
        />
        <div className="max-w-4xl mx-auto flex flex-col items-center absolute top-[30%]  right-0 left-0 z-20">
          <div className="flex flex-col items-center justify-center">
            <p className="text-3xl lg:text-5xl text-white font-semibold ">
              About Us
            </p>
            <p className="text-xs text-center mt-4 text-white w-[80%] lg:w-full">
              McNaughtans Pty Ltd was established in South Africa more than 75
              years ago. With an extremely strong emphasis in the Bus, Truck and
              Trailer industry, McNaughtans during this time has further
              progressed their business throughout the world. We source,
              manufacture and supply around the globe.
            </p>
          </div>
        </div>
        <img
          src={TioTransparent}
          className="h-[600px] absolute bottom-0 right-0 z-20 hidden lg:block"
          alt="team"
        />
        <div className="w-full h-[700px] bg-black/40 z-10 absolute top-0 right-0 " />
      </div>
      <div className="w-full relative max-w-4xl mx-auto flex flex-col lg:flex-row items-center py-10 gap-10">
        <img
          src={AussieMap}
          className="h-40 lg:h-96 object-cover"
          alt="aussie-map"
        />
        <p className="text-[14px] w-[70%] lg:w-full text-center lg:text-left">
          Since 2003 McNaughtans has opened an Australian owned company with
          branches throughout the majority of states servicing Australia and a
          supply distribution into New Zealand.
          <br />
          <br />
          McNaughtans has also been allocated a number of Supply
          Distributorships for World Class Products. The business has brought
          with it a rich experience in the Bus, Truck and Trailer Industry.
          <br />
          <br />
          McNaughtans has acquired a number of complementary businesses to its
          network over the past years and recently has been required to move to
          a brand new purpose built facility due to the continued expansion of
          the business.
        </p>
      </div>
      <div className="bg-[#f8f8f8]  w-full ">
        <div className="max-w-4xl mx-auto flex w-[70%] lg:w-full flex-col items-start py-10 space-y-4">
          <p className="text-3xl font-semibold">Our Mission at McNaughtans</p>
          <p className="text-xs  py-4">
            We at McNaughtans wish to assist our clients to achieve new
            production standards in their builds and offer our services to
            produce new and innovative products. We aim to supply high quality
            products at a competitive price, to keep up with industry standards
            and to maintain strong links with our customers and their needs.
          </p>
          <p className="text-xs lg:text-sm">
            Customer satisfaction is the{" "}
            <span className="italic font-medium">"Modus Operandi"</span> that
            McNaughtans bases all its foundations on.
          </p>
          <p className="text-xs lg:text-sm">
            McNaughtans stands by its motto,{" "}
            <span className="font-medium italic">
              "where there is a will, there is a way"
            </span>
          </p>
          <p className="text-xs lg:text-sm font-medium text-[#d12229]">
            Rodney Meaker (Managing Director)
          </p>
          <p className="font-medium text-sm">MCNAUGHTANS PTY LTD</p>
        </div>
        <div className="w-full relative ">
          <img
            src={Banner2}
            className="w-full h-[500px] lg:h-[700px] object-cover z-10"
            alt="banner"
          />

          <div className="absolute top-0 translate-y-[50%]  flex flex-col items-center bg-black/70 w-[80%] lg:w-[30%] right-0 left-0 text-white mx-auto p-6 lg:p-14">
            <p className="text-white text-xl lg:text-3xl text-center font-semibold">
              Work with us
            </p>
            <p className="py-3 text-xs lg:text-sm text-center italic">
              The McNaughtans team across Australia, South Africa, Spain and
              America look forward to supplying your business.
            </p>
            <button className="border-2 hover:bg-white hover:text-black transition px-7 lg:px-10 py-2 lg:py-3 border-white text-xs lg:text-sm items-center justify-center mt-4">
              Contact us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
