import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/oceania.json";

const MapChartOceania = () => {
  const markers = [
    {
      markerOffset: -15,
      name: "Brisbane",
      coordinates: [153.025131, -27.46977],
    },
    {
      markerOffset: 25,
      name: "Melbourne",
      coordinates: [144.963058, -37.813629],
    },
    { markerOffset: 25, name: "Sidney", coordinates: [151.20929, -33.86882] },
    { markerOffset: 25, name: "Perth", coordinates: [115.860458, -31.950527] },
  ];

  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-138, 25, 0],

        scale: 1000,
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#DDD"
              stroke="#FFF"
            />
          ))
        }
      </Geographies>
      {markers.map(({ name, coordinates, markerOffset }: any) => (
        <Marker key={name} coordinates={coordinates}>
          <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "system-ui", fill: "#e1ad01 " }}
          >
            {name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default MapChartOceania;
