import { catalogues } from "src/App";
import { ListItem } from "./ListItem";
import SidebarMenu from "./sidebar-menu";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "./ui/navigation-menu";

import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
// import { BsFacebook, BsLinkedin, BsInstagram } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import "./Header.css";

interface HeaderProps {
  onClick: () => void;
  bgColor: string;
}

const Header = ({ onClick, bgColor }: HeaderProps) => {
  const navigate = useNavigate();
  return (
    <div className={`w-full  py-2  bg-[#d12229]  `}>
      <div className="flex items-start lg:items-center justify-between  mx-10 lg:max-w-4xl lg:mx-auto ">
        <img
          onClick={() => navigate("/")}
          src={require("../assets/logo.png")}
          className="h-12 lg:h-16 object-contain cursor-pointer"
          alt="mcnaughtans-logo"
        />
        <div className="lg:hidden">
          <SidebarMenu onClick={onClick} isInitiallyOpen={false} />
        </div>
        <NavigationMenu className="hidden lg:block z-30">
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger className="bg-transparent text-white hover:bg-transparent">
                Catalogues
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
                  {catalogues.map((component) => (
                    <ListItem
                      key={component.description}
                      title={component.continent}
                      href={component.href}
                    >
                      {component.description}
                    </ListItem>
                  ))}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
        <NavigationMenu className="hidden lg:block z-30">
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger className="bg-transparent text-white hover:bg-transparent">
                About
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid w-[200px] gap-3 p-4  ">
                  <ListItem title="About Us" href="/about" className="w-full">
                    Our Story
                  </ListItem>
                  {/* {catalogues.map((component) => (
                    <ListItem
                      key={component.description}
                      title={component.continent}
                      href={component.href}
                    >
                      {component.description}
                    </ListItem>
                  ))} */}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
        <IconContext.Provider
          value={{ color: "white", className: "global-class-name" }}
        >
          <div className=" items-center space-x-2  hidden lg:inline-flex">
            <a
              href="https://www.facebook.com/McNaughtans"
              target="_blank"
              rel="noreferrer"
              className="text-white h-6 w-6 rounded items-center justify-center  flex"
            >
              <FaFacebook
                id="icon"
                className="h-5 w-5 fill-white text-white filter stroke-white "
              />
            </a>

            <a
              href="https://au.linkedin.com/company/mcnaughtansptyltd"
              target="_blank"
              rel="noreferrer"
              // className="bg-white h-6 w-6 rounded items-center justify-center flex"
            >
              <div className="text-white">
                <FaLinkedin
                  id="icon"
                  className="h-5 w-5 text-white filter "
                  color="white"
                />
              </div>
            </a>
            <a
              href="https://www.instagram.com/mcnaughtans_pty_ltd/"
              target="_blank"
              rel="noreferrer"
              className="h-6 w-6 rounded items-center justify-center flex"
            >
              <FaInstagram
                id="icon"
                style={{ color: "white" }}
                className="h-5 w-5 text-white filter "
                // className="h-5 w-5 fill-white text-white bg-white"
                color="white"
              />
            </a>
          </div>
        </IconContext.Provider>
      </div>
    </div>
  );
};

export default Header;
