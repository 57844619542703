import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "src/components/ui/dialog";
import { Button } from "src/components/ui/button";
import { Dispatch, SetStateAction } from "react";
import MapChartNorthAmerica from "./MapChartNorthAmerica";
import MapChartEurope from "./MapChartEurope";
import MapChartAfrica from "./MapChartAfrica";
import MapChartOceania from "./MapChartOceania";
import { ExternalLink } from "lucide-react";

interface ModalCountryProps {
  country: string;
  countryCode: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  geoUrl: string;
}

const ModalCountry = ({
  country,
  countryCode,
  open,
  setOpen,
  geoUrl,
}: ModalCountryProps) => {
  console.log(country);

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {country === "United States" && "North America"}
            {country === "Canada" && "North America"}
            {country === "Spain" && "Europe"}
            {country === "South Africa" && "Africa"}
            {country === "Australia" && "Oceania"}
          </DialogTitle>
          <DialogDescription>
            {/* This action cannot be undone. Are you sure you want to permanently
            delete this file from our servers? */}
          </DialogDescription>
        </DialogHeader>
        {country === "United States" && <MapChartNorthAmerica />}
        {country === "Canada" && <MapChartNorthAmerica />}
        {country === "Spain" && <MapChartEurope />}
        {country === "South Africa" && <MapChartAfrica />}
        {country === "Australia" && <MapChartOceania />}

        {/* <MapChart country={country} /> */}
        <DialogFooter className="ml-auto">
          {country === "Australia" && (
            <a
              href="https://www.mcnaughtans.com.au"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                type="button"
                size={"sm"}
                className="text-[14px] lg:text-[16px]"
              >
                Go to Website <ExternalLink className="h-5 w-5 ml-2" />
              </Button>
            </a>
          )}
          {country === "South Africa" && (
            <a
              href="https://www.mcnaughtans.co.za"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                type="button"
                size={"sm"}
                className="text-[14px] lg:text-[16px]"
              >
                Go to Website <ExternalLink className="h-5 w-5 ml-2" />
              </Button>
            </a>
          )}
          {country === "Spain" && (
            <a
              href="https://www.mcnaughtans.es"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                type="button"
                size={"sm"}
                className="text-[14px] lg:text-[16px]"
              >
                Go to Website <ExternalLink className="h-5 w-5 ml-2" />
              </Button>
            </a>
          )}
          {country === "United States" && (
            <a
              href="https://www.mcnaughtans.com"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                type="button"
                size={"sm"}
                className="text-[14px] lg:text-[16px]"
              >
                Go to Website <ExternalLink className="h-5 w-5 ml-2" />
              </Button>
            </a>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ModalCountry;
