import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/africa.json";

const MapChartAfrica = () => {
  const markers = [
    {
      markerOffset: -37,
      name: "Durban",
      coordinates: [31.049999, -29.883333],
    },
    {
      markerOffset: 25,
      name: "Johannesburg",
      coordinates: [28.034088, -26.195246],
    },
    {
      markerOffset: 25,
      name: "Cape Town",
      coordinates: [18.4233, -33.918861],
    },
    {
      markerOffset: 50,
      name: "Empangeni",
      coordinates: [31.89329, -28.76197],
    },
  ];

  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-18, 24, 0],

        scale: 1250,
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#DDD"
              stroke="#FFF"
            />
          ))
        }
      </Geographies>

      {markers.map(({ name, coordinates, markerOffset }: any) => (
        <Marker key={name} coordinates={coordinates}>
          <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "system-ui", fill: "#e1ad01" }}
          >
            {name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default MapChartAfrica;
