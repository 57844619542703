import React, { useEffect, useState } from "react";
import "./App.css";

import type { CountryContext } from "react-svg-worldmap";
import WorldMap from "react-svg-worldmap";
import SpainFlag from "./assets/Bandera_de_España.png";
import UnitedStatesFlag from "./assets/Flag_of_the_United_States.png";
import SouthAfricanFlag from "./assets/Flag_of_South_Africa.png";
import CanadaFlag from "./assets/Flag_of_Canada_(Pantone).png";
import AustralianFlag from "./assets/Flag_of_Australia_(converted).png";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./components/ui/accordion";
import ModalCountry from "./components/modal-country";

import Header from "./components/Header";
import { FiExternalLink } from "react-icons/fi";

export const catalogues = [
  {
    continent: "Australia",
    href: "https://www.mcnaughtans.com.au/ecatalogue/full-edition/",
    description: "McNaughtans Australia",
  },
  {
    continent: "Europe",
    href: "https://www.mcnaughtans.com.au/ecatalogue/full-edition/",
    description: "McNaughtans Spain",
  },
];

function App() {
  const [state, setState] = useState({
    cName: "",
    iso: "",
  });
  const [open, setOpen] = useState(false);
  const data = [
    { country: "us", value: 1 }, // united states
    { country: "ca", value: 1 }, // cánada
    { country: "es", value: 1 }, // spain
    { country: "za", value: 4 }, // south africa
    { country: "au", value: 4 }, // australia
  ];

  const [geoUrl, setGeoUrl] = useState("");
  const [openSheet, setOpenSheet] = useState(false);

  const data2 = [
    {
      country: "us",

      continent: "America",
      countries: [
        {
          name: "United States of America",
          flag: UnitedStatesFlag,
          sedes: [{ name: "Texas", phoneNumber: "+1 (469) 555-3890" }],
        },
        {
          name: "Canada",
          flag: CanadaFlag,
          sedes: [{ name: "Vancouver", phoneNumber: "+1 604 555 5555" }],
        },
      ],
    },
    {
      country: "za",
      continent: " Africa",
      countries: [
        {
          name: "South Africa",
          flag: SouthAfricanFlag,
          sedes: [
            { name: "Durban", phoneNumber: "+27 (0) 860 002 080" },
            { name: "Johannesburg", phoneNumber: "+27 (0) 860 008 080" },
            { name: "Cape Town", phoneNumber: "+27 (0) 21 939 0021" },
            { name: "Empangeni", phoneNumber: "+27 (0) 35 787 0212" },
          ],
        },
      ],
      // name: "South Africa",
    },
    {
      country: "es",
      continent: "Europe",
      countries: [
        {
          name: "Spain",
          flag: SpainFlag,
          sedes: [{ name: "Valencia", phoneNumber: "+34 641 88 06 97" }],
        },
      ],
      // name: "Spain",
    },

    {
      country: "au",
      continent: "Oceania",
      countries: [
        {
          name: "Australia",
          flag: AustralianFlag,
          sedes: [
            { name: "Melbourne", phoneNumber: "+03 9768 6666" },
            { name: "Brisbane", phoneNumber: "+07 32677722" },
            { name: "Sydney", phoneNumber: "+02 96095122" },
            { name: "Perth", phoneNumber: "+08 92481833" },
          ],
        },
      ],
    },
  ];

  const getHref = ({ countryName, countryCode }: CountryContext) => ({
    href:
      data.filter((e) => e.country === countryCode.toLowerCase()).length > 0
        ? ` https://www.${encodeURIComponent(
            countryCode.toLowerCase()
          )}.mcnaughtans.com`
        : "",
    target:
      data.filter((e) => e.country === countryCode.toLowerCase()).length > 0
        ? "_blank"
        : "_self",
  });

  const clickAction = React.useCallback(
    ({ countryName, countryCode }: CountryContext) => {
      setState({
        cName: countryName,
        iso: countryCode,
        // val: formattedNumber(countryValue, 2),
      });
      if (
        countryName === "Spain" ||
        countryName === "Canada" ||
        countryName === "United States" ||
        countryName === "South Africa" ||
        countryName === "Australia"
      ) {
        setOpen(true);
      }
    },
    []
  );

  useEffect(() => {
    switch (state.cName) {
      case "United States":
        setGeoUrl(
          "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/north-america.json"
        );
        break;
      case "Canada":
        setGeoUrl(
          "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/north-america.json"
        );
        break;
      case "Spain":
        setGeoUrl(
          "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/europe.json"
        );
        break;

      case "South Africa":
        setGeoUrl(
          "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/africa.json"
        );
        break;
    }
  }, [state]);

  const onClick = () => {
    console.log("clicked");
    setOpenSheet(true);
  };

  return (
    <div className="h-full w-screen  overflow-y-auto overflow-x-hidden relative bg-white">
      {open && (
        <ModalCountry
          open={open}
          country={state.cName}
          countryCode={state.iso}
          setOpen={setOpen}
          geoUrl={geoUrl}
        />
      )}
      <Header onClick={onClick} bgColor="#DAD4B5" />

      <div className="max-w-4xl mx-auto">
        <WorldMap
          color="#d12229"
          value-suffix="mcnaughtans"
          size={"xxl"}
          richInteraction
          data={data}
          backgroundColor="transparent"
          onClickFunction={clickAction}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3  lg:max-w-4xl lg:mx-auto mx-10 bg-[#f8f8f8] px-4 rounded ">
        {data2.map((country) => (
          <Accordion type="single" collapsible>
            <AccordionItem value={`item-${country.continent}`}>
              <AccordionTrigger className="text-sm lg:text-[16px]">
                {country.continent}
              </AccordionTrigger>
              <AccordionContent>
                {country.countries.map((country) => (
                  <DropdownMenu>
                    <DropdownMenuTrigger className="flex flex-col items-center gap-2 py-1">
                      <div className="flex items-center gap-2">
                        <img
                          alt={`country-${country.name}`}
                          src={country.flag}
                          className="lg:h-6 lg:w-10 h-4 w-8 object-cover"
                        />
                        <p className="text-[14px] lg:text-sm">{country.name}</p>
                      </div>
                      {/* {country.name === "Australia" && (
                        <a
                          href="https://www.mcnaughtans.com.au/ecatalogue/full-edition/"
                          target="_blank"
                          rel="noreferrer"
                          className="  flex items-center gap-2 w-full"
                        >
                          <img
                            src="https://mcnaughtans.myshopify.com/cdn/shop/files/catalogue-hero-book.png?v=1691064003&width=750"
                            className="h-10 w-7 object-cover"
                          />
                          <p className="text-sm">Catalogue</p>
                        </a>
                      )} */}
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="">
                      {country.sedes.map((sede) => (
                        <DropdownMenuItem>
                          {sede.name} | {sede.phoneNumber}
                          {country.name === "Spain" && (
                            <a
                              href="https://www.mcnaughtans.es"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FiExternalLink className="h-5 w-5 text-black fill-white ml-1" />
                            </a>
                          )}
                          {country.name === "South Africa" && (
                            <a
                              href="https://www.mcnaughtans.co.za"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FiExternalLink className="h-5 w-5 text-black fill-white ml-1" />
                            </a>
                          )}
                          {country.name === "Australia" && (
                            <a
                              href="https://www.mcnaughtans.com.au"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FiExternalLink className="h-5 w-5 text-black fill-white ml-1" />
                            </a>
                          )}
                          {(country.name === "United States of America" ||
                            country.name === "Canada") && (
                            <a
                              href="https://www.mcnaughtans.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FiExternalLink className="h-5 w-5 text-black fill-white ml-1" />
                            </a>
                          )}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                ))}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

export default App;
