import React, { useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";
import { FiExternalLink } from "react-icons/fi";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";

interface SidebarMenuProps {
  onClick: () => void;
  isInitiallyOpen: boolean;
}

const SidebarMenu = ({ onClick, isInitiallyOpen }: SidebarMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    // setIsOpen((prev) => !prev);
    onClick();
  };
  return (
    <Sheet>
      <SheetTrigger>
        <button
          onClick={handleClick}
          type="button"
          className={`w-5 h-4 flex justify-around flex-col flex-wrap z-10 cursor-pointer `}
        >
          <div
            className={`bg-white block w-4 h-[0.15rem] rounded transition-all origin-[1px] ${
              isOpen ? "rotate-45" : "rotate-0"
            }`}
          />
          <div
            className={`bg-white block w-4 h-[0.15rem] rounded transition-all origin-[1px] ${
              isOpen ? "translate-x-full bg-transparent" : "translate-x-0"
            }`}
          />
          <div
            className={`bg-white block w-4 h-[0.15rem] rounded transition-all origin-[1px] ${
              isOpen ? "rotate-[-45deg]" : "rotate-0"
            }`}
          />
        </button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader onClick={() => navigate("/")}>
          <SheetTitle className="text-left">McNaughtans</SheetTitle>
          <SheetDescription className="text-[15px] text-left">
            Bus, Truck and Trailer Hardware
          </SheetDescription>
        </SheetHeader>
        <div className="grid gap-4 py-4">
          <p className="text-[15px] text-gray-400 border-t pt-4">Catalogues</p>
          <div className="border-b pb-4">
            <a
              className="flex items-center justify-between"
              href="https://www.mcnaughtans.com.au/ecatalogue/full-edition/"
            >
              <p className="text-[14px]">McNaughtans AU</p>
              <FiExternalLink />
            </a>
          </div>
          <div>
            <a
              className="flex items-center justify-between"
              href="https://www.mcnaughtans.com.au/ecatalogue/full-edition/"
            >
              <p className="text-[14px]">McNaughtans ES</p>
              <FiExternalLink />
            </a>
          </div>
          <p className="text-[15px] text-gray-400 border-t pt-4">About</p>
          <div onClick={() => navigate("/about")} className="">
            <p className="text-[14px]">Our Story</p>
          </div>
        </div>
        <SheetFooter className="absolute bottom-10 w-full left-0 right-0">
          {/* <SheetContent> */}
          <div className="flex items-center gap-1  justify-center ">
            <a
              href="https://www.facebook.com/McNaughtans"
              target="_blank"
              rel="noreferrer"
              className="bg-white h-6 w-6 rounded items-center justify-center flex"
            >
              <AiFillFacebook className="h-5 w-5 text-white  " />
            </a>

            <a
              href="https://au.linkedin.com/company/mcnaughtansptyltd"
              target="_blank"
              rel="noreferrer"
              className="bg-white h-6 w-6 rounded items-center justify-center flex"
            >
              <AiFillLinkedin className="h-5 w-5 text-white " />
            </a>
            <a
              href="https://www.instagram.com/mcnaughtans_pty_ltd/"
              target="_blank"
              rel="noreferrer"
              className="bg-white h-6 w-6 rounded items-center justify-center flex"
            >
              <AiFillInstagram className="h-5 w-5 text-white " />
            </a>
          </div>
          {/* </SheetContent> */}
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default SidebarMenu;
